<template>
  <div class="blockUI">
    <div class="blockOverlay"></div>
    <div class="blockMsg">
      <h5>{{ blockMsg }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockUI',
  props: {
    blockMsg: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>

<style lang="scss" scoped>
.blockUI {
  .blockOverlay {
    z-index: 1000;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 99%;
    height: 95%;
    top: 0.5em;
    left: 1em;
    background-color: black;
    opacity: 0.3;
    cursor: wait;
    position: absolute;
  }
  .blockMsg {
    z-index: 1011;
    position: absolute;
    padding: 8px 4px 0px 4px;
    width: 40%;
    top: 40%;
    left: 33%;
    text-align: center;
    color: black;
    background-color: white;
    cursor: wait;
    box-shadow: 2px 2px 2px 2px grey;
  }
}
</style>
